import { TailSpin } from "react-loader-spinner";

const FullbackLoader = () => {
  return (
    <div className="fullback-loader">
      <TailSpin
        height="80"
        width="80"
        color="#000"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default FullbackLoader;
